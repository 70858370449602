import type { UseSeoMetaInput, UseHeadInput, MergeHead } from '@unhead/vue';
import type { Entry } from '~/statamic/types/entry';
import type { GlobalSeo } from '~/statamic/types/global';

const FAVICON_PATH = '/images/favicon';

export type EntryMetaResponse = {
  seo: UseSeoMetaInput;
  head: UseHeadInput<MergeHead>,
};

function socialImage(global: GlobalSeo, entry: Entry) {
  // Retrieve a social image for entry
  if (entry.og_image?.responsiveAsset?.url) {
    return {
      url: entry.og_image.responsiveAsset.url,
      width: entry.og_image.responsiveAsset.width,
      height: entry.og_image.responsiveAsset.height,
    };
  }

  // Retrieve a global social image
  if (global.og_image?.responsiveAsset?.url) {
    return {
      url: global.og_image?.responsiveAsset.url,
      width: global.og_image?.responsiveAsset.width,
      height: global.og_image?.responsiveAsset.height,
    };
  }

  return null;
}

function appendSiteName(global: GlobalSeo, text: string) {
  if (!global.site_name || !global.title_separator?.value) {
    return text;
  }

  return `${text} ${global.title_separator.value} ${global.site_name}`;
}

export default (entry: Entry): EntryMetaResponse | null => {
  const { $statamic } = useNuxtApp();
  const globalSeo = $statamic.global('seo') as GlobalSeo;

  if (!globalSeo) {
    return null;
  }

  const metaTitle = appendSiteName(globalSeo, entry.seo_title || entry.title);
  const socialTitle = appendSiteName(globalSeo, entry.og_title || entry.seo_title || entry.title);
  const socialDescription = entry.og_description || entry.seo_description;
  const socialImageDefinition = socialImage(globalSeo, entry);
  const googleVerification = globalSeo.use_google_site_verification
    ? globalSeo.google_site_verification
    : undefined;

  return {
    seo: {
      title: metaTitle,
      description: entry.seo_description,
      ogTitle: socialTitle ?? metaTitle,
      ogDescription: socialDescription ?? metaTitle,
      ogImage: socialImageDefinition,
      ogType: 'website',
      ogLocale: $statamic.site.locale.replace('_', '-').toLowerCase(),
      twitterCard: 'summary_large_image',
      robots: {
        index: !entry.seo_noindex,
        follow: !entry.seo_nofollow,
      },
    },

    head: {
      link: [
        {
          rel: 'apple-touch-icon', href: `${FAVICON_PATH}/apple-touch-icon.png`, type: 'image/x-icon', sizes: '180x180',
        },
        {
          rel: 'icon', href: `${FAVICON_PATH}/favicon.svg`, type: 'image/svg+xml',
        },
        {
          rel: 'icon', href: `${FAVICON_PATH}/favicon.png`, type: 'image/png',
        },
        {
          rel: 'icon', href: `${FAVICON_PATH}/favicon-32x32.png`, type: 'image/x-icon', sizes: '32x32',
        },
        {
          rel: 'icon', href: `${FAVICON_PATH}/favicon-16x16.png`, type: 'image/x-icon', sizes: '16x16',
        },
        {
          rel: 'manifest', href: `${FAVICON_PATH}/site.webmanifest`,
        },
        {
          rel: 'mask-icon', href: `${FAVICON_PATH}/safari-pinned-tab.svg`, color: '#129fe6',
        },
      ],

      meta: [
        ...(googleVerification ? [{
          name: 'google-site-verification',
          content: googleVerification,
        }] : []),

        {
          name: 'msapplication-TileColor', content: '#129fe6',
        },
        {
          name: 'theme-color', content: '#129fe6',
        },
      ],
    },
  };
};
