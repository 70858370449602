<template>
  <Blocks :meta="meta" :entry="entry" />
</template>

<script setup lang="ts">
const { $statamic } = useNuxtApp();
const entry = await useStatamicEntry({ uri: useRoute().path });
const meta = formatEntryMeta(entry);
const jsonLd = formatEntryJsonLd(entry);

useHead({
  htmlAttrs: {
    lang: $statamic.site.locale.replace('_', '-').toLowerCase(),
  },

  link: [
    ...(meta?.head?.link ? meta.head.link : []),
  ],

  meta: [
    ...(meta?.head?.meta ? meta.head.meta : []),
  ],

  script: [
    jsonLd,
  ],
});

if (entry.url === '/404') {
  setResponseStatus(useRequestEvent()!, 404);
}

if (meta) {
  useSeoMeta(meta.seo);
}
</script>
