import { formatISO } from 'date-fns';
import type { Entry } from '~/statamic/types/entry';

const articleJsonLd = (entry: Entry, baseUrl: string) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${baseUrl}${entry.url}`,
    },
    headline: entry.title,
    image: [entry.image?.md.url],
    datePublished: formatISO(new Date(entry.date)),
    publisher: { '@id': `${baseUrl}#identity` },
    copyrightHolder: { '@id': `${baseUrl}#identity` },
    author: entry.author ? [{
      '@type': 'Person',
      name: entry.author.title,
      url: `${baseUrl}${entry.author.url}`,
    }] : [],
    description: '',
  };
};

const vacancyJsonLd = (entry: Entry, global: Function, baseUrl: string) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: entry.title,
    description: entry.text,
    datePosted: formatISO(new Date(entry.date)),
    employmentType: entry.employment_type?.value ?? 'FULL_TIME',
    hiringOrganization: {
      '@type': 'Organization',
      name: global('company.name'),
      logo: `${baseUrl}/images/brand/logo-small.png`,
      sameAs: baseUrl,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        postalCode: global('company.postal_code'),
        streetAddress: `${global('company.street')} ${global('company.house_number')}`,
        addressRegion: 'Gelderland',
        addressCountry: 'NL',
        addressLocality: global('company.city'),
      },
    },
  };
};

export default (entry: Entry) => {
  const BASE_URL = useRequestURL().origin;
  const { $statamic: { global } } = useNuxtApp();

  let jsonLd = null;

  if (entry.collection?.handle === 'articles') {
    jsonLd = articleJsonLd(entry, BASE_URL);
  }

  if (entry.collection?.handle === 'vacancies') {
    jsonLd = vacancyJsonLd(entry, global, BASE_URL);
  }

  return jsonLd ? {
    type: 'application/ld+json',
    children: JSON.stringify(jsonLd, null, ''),
  } : {};
};
