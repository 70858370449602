<template>
  <div v-if="entry.blocks?.length" class="blocks">
    <DelayHydration>
      <section
        v-for="(block, index) in entry.blocks"
        :key="block.id"
        ref="renderedBlocks"
        class="blocks__block"
        :id="block.type"
        :data-block-type="block.type"
        :data-block-next="entry.blocks[index + 1]?.type"
        :data-block-prev="entry.blocks[index - 1]?.type"
        :data-background-color="block.background_color?.value"
      >
        <Component
          v-if="blocksObj[block.type] !== undefined"
          :is="blocksObj[block.type]"
          :meta="meta"
          :entry="entry"
          :block="block"
          :block-index="index"
        />
      </section>
    </DelayHydration>
  </div>
</template>

<script setup lang="ts">
import type { ConcreteComponent } from 'vue';
import type { Entry } from '~/statamic/types/entry';
import type { EntryMetaResponse } from '~/statamic/utils/formatEntryMeta';

defineProps<{
  meta?: EntryMetaResponse | null;
  entry: Entry;
}>();

const blocksObj: { [key: string]: ConcreteComponent | string } = {
  hero: resolveComponent('LazyBlocksHero'),
  hero_page: resolveComponent('LazyBlocksHeroPage'),
  hero_service: resolveComponent('LazyBlocksHeroService'),
  hero_project: resolveComponent('LazyBlocksHeroProject'),
  hero_article: resolveComponent('LazyBlocksHeroArticle'),
  hero_course: resolveComponent('LazyBlocksHeroCourse'),
  hero_vacancy: resolveComponent('LazyBlocksHeroVacancy'),
  hero_employee: resolveComponent('LazyBlocksHeroEmployee'),
  hero_landing_page: resolveComponent('LazyBlocksHeroLandingPage'),
  text_images: resolveComponent('LazyBlocksTextImages'),
  accordion: resolveComponent('LazyBlocksAccordion'),
  video: resolveComponent('LazyBlocksVideo'),
  image: resolveComponent('LazyBlocksImage'),
  title_entries: resolveComponent('LazyBlocksTitleEntries'),
  footer: resolveComponent('LazyBlocksFooter'),
  title_text: resolveComponent('LazyBlocksTitleText'),
  text_image: resolveComponent('LazyBlocksTextImage'),
  awards: resolveComponent('LazyBlocksAwards'),
  statistics: resolveComponent('LazyBlocksStatistics'),
  employees: resolveComponent('LazyBlocksEmployees'),
  call_to_action: resolveComponent('LazyBlocksCallToAction'),
  contact_form: resolveComponent('LazyBlocksContactForm'),
  projects: resolveComponent('LazyBlocksProjects'),
  text: resolveComponent('LazyBlocksText'),
  two_image_columns: resolveComponent('LazyBlocksTwoImageColumns'),
  three_image_columns: resolveComponent('LazyBlocksThreeImageColumns'),
  three_video_columns: resolveComponent('LazyBlocksThreeVideoColumns'),
  articles: resolveComponent('LazyBlocksArticles'),
  social_share: resolveComponent('LazyBlocksSocialShare'),
  courses: resolveComponent('LazyBlocksCourses'),
  two_text_columns: resolveComponent('LazyBlocksTwoTextColumns'),
  title_text_image: resolveComponent('LazyBlocksTitleTextImage'),
  services: resolveComponent('LazyBlocksServices'),
  title_list: resolveComponent('LazyBlocksTitleList'),
  roadmap: resolveComponent('LazyBlocksRoadmap'),
  course_signup_form: resolveComponent('LazyBlocksCourseSignupForm'),
  title_four_usps_columns: resolveComponent('LazyBlocksTitleFourUspsColumns'),
  title_text_four_usps_columns: resolveComponent('LazyBlocksTitleTextFourUspsColumns'),
  title_text_three_columns: resolveComponent('LazyBlocksTitleTextThreeColumns'),
  title_button: resolveComponent('LazyBlocksTitleButton'),
  vacancies: resolveComponent('LazyBlocksVacancies'),
  text_employees: resolveComponent('LazyBlocksTextEmployees'),
  planning: resolveComponent('LazyBlocksPlanning'),
  reviews: resolveComponent('LazyBlocksReviews'),
  vacancy_apply_form: resolveComponent('LazyBlocksVacancyApplyForm'),
  product_request_form: resolveComponent('LazyBlocksProductRequestForm'),
  title_labels: resolveComponent('LazyBlocksTitleLabels'),
  links: resolveComponent('LazyBlocksLinks'),
};

const renderedBlocks = ref([]);
const { height } = useWindowSize();
const { changeHeaderMode } = useHeader();
const { changeCanvasColor } = useCanvas();

const blocksWithLightHeader = [
  'hero_project',
  'footer',
];

useRafFn(() => {
  if (!renderedBlocks.value) return;

  const windowIntersection = height.value / 2;

  renderedBlocks.value.forEach((block: HTMLDivElement) => {
    const { top: blockTop, bottom: blockBottom } = block.getBoundingClientRect();

    if (blockTop <= windowIntersection && blockBottom >= windowIntersection) {
      changeHeaderMode(blocksWithLightHeader.includes(block.dataset?.blockType));
      changeCanvasColor(block.dataset?.backgroundColor);
    }
  });
});
</script>
